

.small-title::before {
  display: inline-block;
  content: "";
  width: 3px;
  height: 75px;
  background: red;
  margin-bottom: -60px;
  margin-right: 5px;
  border-radius: 10px;
}

.location .location-item .item-content ion-icon{
  margin-right: 5px;
}

.location .location-item .item-content {
  color: var(--v2-black);
  font-size: 14px;
  font-family: poppins;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v2-modal-title {
  font-family: poppins !important;
  text-transform: capitalize !important;
  font-weight: 500 !important;
  letter-spacing: 0.1px !important;
}

.popup-container .popup-container-inner {
  /*border-radius: 0px !important;
  box-shadow: none !important;
  width: 100% !important;
  min-width: 300px !important;
  min-height: 100% !important;
  background: #fff !important;
  margin: auto !important;
  position: absolute !important;
  top: 0 !important;
  transform: translate(-50%, 0%)!important;*/
    border-radius: 0px !important;
    box-shadow: none !important;
    /* width: 100% !important; */
    min-width: 300px !important;
    /* min-height: 100% !important; */
    background: #fff !important;
    margin: auto !important;
    /* position: absolute !important; */
    /* top: 0 !important; */
    /* transform: translate(-50%, 0%) !important; */
    border-radius: 5px !important;

}
.custom-popup .modal-wrapper {
  border-radius: 0 !important;
}
.footer-fixed-submits{
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 99;
  bottom: 0;
}

app-checkout{
  width: 100% !important;
}


.title-container{
  padding: 20px 10px 10px 20px;

  .title{
    font-family: poppins;
    font-size: 16px;
    color: var(--v2-black);
    font-weight: 500;
    letter-spacing: 0.1px;
  }
  .icon{
    margin-right: 10px;
    font-size: 32px;
    color: var(--v2-base);
  }
  .small {
    font-size: 12px;
    line-height: 10px;
    opacity: 0.6;
  }

  .close-btn{
    font-size: 22px;
  }
}


.cart-footer ion-col{
  padding: 3px !important;
  font-size: 13px !important;
  font-weight: bold !important;
  color: var(--v2-black) !important;
  font-weight: normal !important;

  &.font-size-18 {
    font-size: 16px !important;
  }

}
.margin-0{
  margin: 0;
}



.v2-button-mobile{
  background: var(--v2-red);
  padding: 0 20px;
  border-radius: 100px;
  color: #fff;
  box-shadow: 0px 1px 1px 2px #dbdbdb;

  .submit-buttons {
    background: var(--v2-red) !important;
    padding: 12px 5px 15px 5px;
    border-radius: 5px;
    color: #f9f9f9;
    box-shadow: 0 1px 4px 1px #b3b3b3;
    font-weight: bold;
    font-size: 12px;
    font-family: poppins;
    font-weight: normal;
    font-size: 16px !important;
    text-align: center;
    text-transform: uppercase;
  }




  .v2-button-mobile-icon {
    padding-right: 5px;
    background: var(--v2-red);
    height: 100%;
    font-size: 22px;
    color: #fff;
  }

  .v2-button-mobile-arrow{
    font-size: 22px;
    color: #fff;
    margin-left: 20px;
  }

  .small-title-button {
    font-size: 12px;
    text-align: center;
    opacity: 0.7;
    color: #fff;
    text-transform: capitalize;
    margin-top: 2px;
  }



}
