/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "theme/custom";

.epit-container {
    padding: 10px 10px;
}

.slider {
    .swiper-pagination {
        display: inline-block;
        width: unset !important;
        margin: auto !important;
        left: calc(50%) !important;
        transform: translateX(-50%) scale(75%);
        background: #fff;
        border-radius: 10px;
        padding-bottom: 2px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 0;
    }
    .logo {
        width: 60px;
        margin: auto;
        position: relative;
        z-index: 999;
        border-radius: 100%;
        border: 2px solid #fff;
        height: 62px;
        background: #fff;
    }
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background: #e30616 !important;
    }
    .swiper-pagination-bullet {
        background: #5e5e5e !important;
        opacity: 1 !important;
    }
}

@font-face {
    font-family: opensans;
    src: url("/assets/fonts/OpenSans-Bold.ttf");
    font-weight: bold;
}

@font-face {
    font-family: opensans;
    src: url("/assets/fonts/OpenSans-Regular.ttf");
}

@font-face {
    font-family: roboto;
    src: url("/assets/fonts/Roboto-Bold.ttf");
    font-weight: bold;
}

@font-face {
    font-family: roboto;
    src: url("/assets/fonts/Roboto-Regular.ttf");
}

@font-face {
    font-family: exo;
    src: url("/assets/fonts/Exo-VariableFont_wght.ttf");
}

body {
    font-family: exo;
}

.custom-popup {
    .modal-wrapper {
        --background: transparent;
        background: transparent !important;
    }
}

.alert.form-error {
    div {
        text-align: right;
        font-size: 12px;
        color: #e31818;
        margin-top: 3px;
    }
}

.error-box {
    width: 100%;
    margin: 10px auto;
    background: #ee4242;
    color: #fff;
    border-radius: 5px;
    text-align: center;
    padding: 10px 0px 12px 00px;
    font-size: 11px;
    font-family: exo;
    font-weight: normal;
    ion-icon {
        /*font-size: 15px;
    margin-bottom: -12px;
    border: 2px solid #fff;
    padding: 3px;
    border-radius: 100px;
    margin-right: 5px;*/
        font-size: 18px;
        //border: 2px solid #fff;
        ///padding: 3px;
        //border-radius: 100px;
        // margin-right: 15px;
        margin-bottom: -5px;
    }
}

.success-box {
    /*
  width: 100%;
  padding: 10px 10px 18px 10px;
  margin: 10px auto;
  background: #3c7c36;
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
  text-align: center;*/
    width: 100%;
    padding: 10px 0px 12px 00px;
    margin: 10px auto;
    background: #3c7c36;
    color: #fff;
    border-radius: 5px;
    font-size: 11px;
    text-align: center;
    font-family: exo;
    font-weight: normal;
    ion-icon {
        /*font-size: 15px;
    margin-bottom: -12px;
    border: 2px solid #fff;
    padding: 3px;
    border-radius: 100px;
    margin-right: 5px;*/
        font-size: 18px;
        //border: 2px solid #fff;
        ///padding: 3px;
        //border-radius: 100px;
        // margin-right: 15px;
        margin-bottom: -5px;
    }
}

.pac-logo:after {
    display: none !important;
}

.pac-container {
    background-color: #f3f1f1;
}

.pac-item {
    padding: 5px;
}

.pointer {
    cursor: pointer;
}

.ion-toolbar {
    position: relative;
    margin-top: -25px;
    .ion-title {
        text-align: left;
        padding: 20px 20px 10px 20px;
        background: var(--ta-primary-color);
        color: #fff;
    }
    .large-title {
        display: block;
        font-size: 18px;
        font-weight: bold;
        ion-icon {
            font-size: 23px;
            margin-bottom: -5px;
        }
    }
}

ion-header {
    background-color: #ffffff;
}

.cart-button {
    z-index: 99;
    display: inline-block;
    padding: 10px;
    position: fixed;
    bottom: 120px;
    right: 20px;
    background: var(--ta-primary-color); // #ff001c
    color: #fff;
    border-radius: 150px;
    width: 75px;
    height: 75px;
    text-align: center;
    transform: rotate(40deg);
    border: 4px dashed #fff;
    transition: 0.5s;
    ion-icon {
        font-size: 25px !important;
        color: #ffffff !important;
    }
    span {
        font-weight: 600;
        font-size: 10px;
        display: block;
        text-align: center;
    }
}

.cart-button.cart-top {
    bottom: calc(100% - 130px);
    transform: rotate(0deg);
}

.cart-button.animating {
    z-index: 99;
    display: inline-block;
    padding: 10px;
    position: fixed;
    top: unset;
    bottom: calc(50% - 25px) !important;
    right: calc(50% - 25px) !important;
    background: var(--ta-primary-color);
    color: #fff;
    border-radius: 150px;
    width: 75px;
    height: 75px;
    text-align: center;
    transform: rotate(0deg);
    border: none;
    &::before {
        content: " ";
        width: 75px;
        height: 75px;
        display: block;
        position: absolute;
        left: -3px;
        top: -3px;
        border-radius: 100%;
        border: 3px dashed #fff;
        animation: cart-animation-1 1.5s infinite linear;
    }
    ion-icon {
        opacity: 1;
        animation: cart-animation-2 1.5s linear;
    }
    span {
        font-weight: 600;
        font-size: 12px;
        display: block;
        text-align: center;
    }
}

.red-button {
    background: var(--ta-primary-color); // #ff001c
    padding: 15px 20px;
    border-radius: 5px;
    color: #fff;
}

.red-button-web {
    background: var(--ta-primary-color-web); // #ff001c
}

.text-center {
    text-align: center !important;
}

.red-color {
    color: #e74c3c;
}

green-color {
    color: #27ae60;
}

.ion-back-opacity {
    opacity: 0.5;
}

@keyframes cart-animation-1 {
    0% {
        transform: rotate(0deg) scale(1);
    }
    50% {
        transform: rotate(90deg) scale(1);
    }
    100% {
        transform: rotate(180deg) scale(1);
    }
}

@keyframes cart-animation-2 {
    0% {
        transform: translateY(0px) scale(1);
        opacity: 0;
    }
    49.9% {
        transform: translateY(0px) scale(1);
        opacity: 0;
    }
    50% {
        transform: translateY(-100px) scale(1);
        opacity: 0;
    }
    75% {
        padding-bottom: 0;
        transform: translateY(0px) scale(1);
        opacity: 1;
    }
    80% {
        padding-bottom: 2px;
        transform: translateY(0px) scale(1.5);
        opacity: 1;
    }
    85% {
        padding-bottom: 0px;
        transform: translateY(0px) scale(1);
        opacity: 1;
    }
    90% {
        padding-bottom: 2px;
        transform: translateY(0px) scale(1.5);
        opacity: 1;
    }
    95% {
        padding-bottom: 0px;
        transform: translateY(0px) scale(1);
        opacity: 1;
    }
    100% {
        padding-bottom: 0px;
        transform: translateY(0px) scale(1);
        opacity: 1;
    }
}

.submit-buttons {
    background: var(--ta-primary-color) !important; // #e8081db5;
    padding: 12px 20px 15px 20px;
    border-radius: 5px;
    color: #f9f9f9;
    box-shadow: 0 1px 4px 1px #b3b3b3;
    font-weight: bold;
    font-size: 12px;
    ion-icon {
        font-size: 20px;
        margin-bottom: -5px;
        margin-right: 10px;
    }
    .text {
        font-size: 12px !important;
        text-transform: full-size-kana;
    }
}

.submit-buttons-web {
    background: var(--ta-primary-color-web) !important; // #e8081db5;
}

.submit-buttons:disabled {
    background: #b3b3b3b5;
    padding: 12px 20px 15px 20px;
    border-radius: 5px;
    color: #646464;
    box-shadow: 0 0px 0px 0px #b3b3b3;
    font-weight: bold;
    font-size: 12px;
}

.sub-items {
    font-size: 12px;
    color: #79797996;
    padding-left: 5px;
    font-family: poppins;
    line-height: 14px;
    ul {
        padding-left: 5px !important;
        li {
            list-style: none;
        }
    }
}

.pb100 {
    padding-bottom: 100px;
}

// padding left 0
.pl-0 {
    padding-left: 0px !important;
}

.height-auto {
    height: auto !important;
}

.text-red {
    color: red !important;
}

.find-nearby-back-button {
    top: 7px !important;
    position: absolute !important;
}

.pt-25 {
    padding-top: 25px !important;
}

.checkout-select {
    .alert-head {
        display: block;
        width: 100%;
        position: relative;
        background: #e30d0d;
        font-weight: 600;
        padding: 5px;
        h2 {
            font-size: 17px;
            color: #fff;
            margin: 0 !important;
            padding: 5px 0;
        }
        .alert-wrapper.sc-ion-alert-ios {
            border-radius: 5px;
        }
    }
    .alert-wrapper.sc-ion-alert-ios {
        border-radius: 5px;
    }
    .alert-message.sc-ion-alert-ios {
        padding-bottom: 0 !important;
    }
    .alert-button.sc-ion-alert-md {
        border-radius: 2px;
        position: relative;
        background-color: transparent;
        color: #f93b5e;
        font-weight: 500;
        text-align: end;
        text-transform: uppercase;
        overflow: hidden;
    }
    .alert-radio-label.sc-ion-alert-ios {
        color: #7b7b7b;
    }
    .alert-button.sc-ion-alert-ios {
        color: #ea4646;
        font-size: 12px;
    }
    [aria-checked="true"].sc-ion-alert-ios .alert-radio-inner.sc-ion-alert-ios {
        border-color: #ea4646;
    }
    [aria-checked="true"].sc-ion-alert-ios .alert-radio-label.sc-ion-alert-ios {
        color: #ea4646;
        font-weight: bold;
    }
}

.m-0 {
    margin: 0px !important;
}

.float-right {
    float: right !important;
}

.web-footer {
    background-color: #403d3d;
    padding: 20px 0;
    .footer-left {
        text-align: left;
        ul {
            list-style: none;
            display: inline-block;
            margin: 0;
            li {
                display: inline;
                a {
                    color: #ffffff;
                    text-decoration: none;
                    margin: 5px;
                }
            }
        }
    }
    .footer-right {
        color: #ffffff;
        text-align: right;
    }
}

.text-uppercase {
    text-transform: uppercase;
}

.not-found {
    width: 80%;
    text-align: center;
    margin-top: 40%;
    margin-left: 10%;
    margin-right: 10%;
    font-size: 20px;
    color: #9a9494;
    ion-icon {
        font-size: 40px;
    }
}

.page-bottom-padding-fix {
    padding-bottom: 200px;
}

.text-right {
    text-align: right;
}

.dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    margin-left: 10px;
    margin-top: 2px;
    &.green-dot {
        background-color: #27ae60;
    }
    &.orange-dot {
        background-color: #ffa500;
    }
    &.red-dot {
        background-color: #da2f11;
    }
}

.text-green {
    color: #27ae60;
}

.text-red {
    color: #da2f11;
}

.text-orange {
    color: #ffa500;
}

.modal-wrapper {
    border-radius: 10px !important;
}

.remove-position-absolute {
    position: relative !important;
}

.ion-spinner-red {
    color: #e30616 !important;
}
.ion-spinner-white {
    color: #ffffff !important;
}
ion-select {
    .select-icon-inner {
        left: -5px !important;
        margin-top: -7px !important;
        border-top: 9px solid !important;
        border-right: 6px solid transparent !important;
        border-left: 6px solid transparent !important;
    }
}

.main-container-for-dishes {
    p {
        margin: 5px;
        margin-left: 10px;
        font-size: 14px;
        color: #828282;
        position: relative;
        padding-left: 10px;
        &::after {
            content: " ";
            width: 8px;
            height: 8px;
            display: block;
            position: absolute;
            top: 4px;
            left: -3px;
            transform: translateY(-50%);
            background: #ececec;
            border-radius: 100%;
        }
    }
}

.small-spinner-inputs {
    width: 15px !important;
    height: 15px !important;
    color: #da2f11;
}

.no-padding {
    padding: 0px !important;
}

.custom-input-label {
    font-size: 14px !important;
    font-weight: 600 !important;
}

.custom-input-value {
    font-size: 14px !important;
}

.pull-right {
    float: right !important;
}

.min-width-100p {
    min-width: 100% !important;
}

.header-height {
    height: 25px;
}

.reservation-success-msg {
    font-size: 12px !important;
}

.reservation-success-msg-web {
    font-size: 18px !important;
    line-height: 1.5 !important;
}

.height-300-px {
    height: 300px !important;
}

.popup-header-icon {
    color: var(--ta-primary-color) !important;
}

.title-1 {
    font-family: Rubik;
    font-weight: 500;
    color: var(--ta-dark-blue);
    font-size: 15px;
}
.container-1 {
    padding: 10px;
}

.container-2 {
    background: var(--ta-creamy);
    padding: 10px;
    font-family: Roboto;
    color: #7d7d7d;
    border-radius: 5px;
    margin-top: 10px;
}

.default-item {
    --background: transparent;
    --color: inherit;
    --inner-padding-start: 0;
    --inner-padding-end: 0;
    --inner-padding-top: 0;
    --inner-padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    --min-height: 5px;
    *[slot="end"] {
        margin-inline-start: 5px;
    }
}

.container-items {
    margin-top: 5px;
}

.blue-tag {
    background: var(--ta-primary-color); // var(--ta-light-blue);
    padding: 5px;
    font-size: 12px;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
}

.spinner {
    width: 120px;
    text-align: center;
    margin: auto;
    height: 35px;
    padding-top: 10px;
    .spinner-center {
        height: 10px;
        width: 1px;
        display: inline-block;
        position: relative;
        span {
            width: 5px;
            height: 5px;
            display: block;
            background: #fff;
            position: absolute;
            transition: 0.5s;
            opacity: 1;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            &:first-child {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                height: 10px;
                width: 5px;
            }
            @for $i from 2 through 7 {
                &:nth-child(#{$i}) {
                    animation: spinner-#{$i} 2s infinite linear;
                }
            }
        }
    }
}

@for $i from 2 through 10 {
    @keyframes spinner-#{$i} {
        0% {
            opacity: 1;
            left: 0;
            height: 5px;
        }
        25% {
            opacity: 1;
            left: 0;
            height: 5px;
        }
        50% {
            opacity: 0;
            left: #{($i) * 10}px;
            height: #{($i) * 5}px;
        }
        75% {
            opacity: 1;
            left: 0;
            height: 5px;
        }
        100% {
            opacity: 1;
            left: 0;
            height: 5px;
        }
    }
}

.ionic5-star-rating {
    ion-button {
        ion-icon {
            color: #fa8208 !important;
        }
    }
}

.rating-component {
    display: inline-flex !important;

    .review-count {
        margin-top: 10px !important;
        font-size: 12px !important;
        margin-left: 5px;
        color: var(--ta-primary-link) !important;
    }
}

.error-text {
    color: red;
}
.error-text-login {
    // color: #ffffff;
    color: #323232;
}

.ion-spinner-red {
    color: #e30616 !important;
}
.ion-spinner-white {
    color: #ffffff !important;
}
ion-select {
    .select-icon-inner {
        left: -5px !important;
        margin-top: -7px !important;
        border-top: 9px solid !important;
        border-right: 6px solid transparent !important;
        border-left: 6px solid transparent !important;
    }
}

.main-container-for-dishes {
    p {
        margin: 5px;
        margin-left: 10px;
        font-size: 14px;
        color: #828282;
        position: relative;
        padding-left: 10px;
        &::after {
            content: " ";
            width: 8px;
            height: 8px;
            display: block;
            position: absolute;
            top: 4px;
            left: -3px;
            transform: translateY(-50%);
            background: #ececec;
            border-radius: 100%;
        }
    }
}

.web-basecontainer {
    margin: auto;
    max-width: 1200px;
}

.default-item {
    --background: transparent;
    --background-hover: transparent;
    --color: inherit;
    --inner-padding-start: 0;
    --inner-padding-end: 0;
    --inner-padding-top: 0;
    --inner-padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    --min-height: 5px;
    --background-activated: transparent;
    --background-activated-opacity: 1;
    *[slot="end"] {
        margin-inline-start: 5px;
    }
}

:host(.header-container .ion-activatable:hover) .item-native::after {
    --background: transparent !important;
    background-color: transparent !important;
}

ion-header {
    // margin-top: var(--header-top-is-android) !important;
    border-top: var(--header-top-is-android) var(--ta-primary-color) solid;
}

ion-header.ion-header {
    background-image: url("assets/new/images/header-bg.png");
    // margin-bottom: -30px;
    // padding-bottom: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    background-color: transparent;

    &.header-md::after {
        background-image: none;
    }

    .ion-toolbar {
        .ion-title {
            text-align: left;
            padding: 20px 10px;
            color: #fff;
            .large-title {
                ion-icon {
                    background: rgba(255, 255, 255, 0.5);
                    padding: 5px;
                    margin-bottom: -7px;
                    border-radius: 5px;
                    margin-right: 5px;
                    color: #373737;
                    font-size: 15px;
                    min-width: unset !important;
                    min-height: 1px !important;
                }
            }
        }
    }
}

// // THIS IS COMMENTED DUE TO AN DISPLAY ERROR OF COMPONENTS
// // (when navigate previous component still visible for few seconds after next component loaded)

// ion-content {
//     --background: transparent;
// }

.new-fields {
    .field-container {
        display: block !important;
        background-color: transparent !important;
        font-family: poppins;
        position: relative;
    }
    .left-content {
        font-size: 14px;
        color: #373737;
        padding-left: 8px;
    }

    .text-fields .input {
        background: #fff !important;
        padding: 10px !important;
        border-radius: 10px;
        margin-top: 5px;
        font-size: 14px;
    }
    .right-content {
        display: inline-block;
        position: absolute;
        right: 20px;
        bottom: 10px;
    }

    .submit-buttons {
        background: var(--ta-primary-link) !important;
        padding: 12px 15px;
        border-radius: 10px;
        color: #f9f9f9;
        box-shadow: none;
        font-weight: 100 !important;
        font-size: 14px;
        font-family: poppins;
    }

    .check-boxes .check-box {
        font-size: 14px !important;
        font-family: poppins !important;
        color: var(--ta-secondary-text);
    }
}

.full-bg {
    background-color: #f7f7f7;
    min-height: 100%;
    ion-header {
        margin-top: var(--header-top-is-android) !important;
    }
}




@import "theme/V2/base";


.title-1 {
  position: relative;
  background: #fff;
  margin-left: 20px;
  font-size: 16px;
  color: var(--v2-black);
  padding: 10px;
  font-weight: 500;
  font-family: poppins;

  &::before{
    content: " ";
    height: 10px;
    width: 10px;
    background-color: #FFF;
    display: inline-block;
    top: 15px;
    //position: absolute;
    border: 3px solid var(--v2-base);
    border-radius: 100%;
    z-index: 9;
    margin-right: 15px;
    margin-bottom: -2px;
  }
}
.full-width{
  width: 100%;
}

.modal-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99;
  background: #f9f9f9;
  padding: 10px 20px;
  border-top: 1px solid #e6e6e6;
}

.modal-margin-box{
  display: block;
  height: 120px;
  width: 100%;
}
.location-item {
  padding: 10px;
  border: 1px solid #dfdfdf;
}
