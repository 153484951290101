.plt-mobile {

  @import "modal";


  .input-fullwidth-container {
    margin-top: 10px;
    padding-left: 10px;
  }

  .inline-block{
    display: inline-block;
  }


  .tite-text {
    font-size: 16px !important;
    display: block;
    font-family: poppins !important;
    line-height: 13px !important;
    color: #fff !important;
  }


  .location-item {
    padding: 5px;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
  }

  .footer-box{
    padding-right: 30px !important;
  }




}

.v2-reviews{
  color: var(--v2-black);
  font-family: poppins;
  font-size: 13px;
  display: inline-block;
  width: 100%;
  /* text-align: right; */
  .inline-block{
    display: inline-block;
  }
}

app-checkout{
  width: 100%;
}
