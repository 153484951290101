.plt-desktop {

  .v2-modal-header{
    &.header-md::after {
      left: 0;
      bottom: -5px;
      background-position: left 0 top -2px;
      position: absolute;
      width: 100%;
      height: 5px;
       background-image:unset;
      background-repeat: repeat-x;
      content: "";
    }
  }


  .v2-modal-container{

    padding: 0 5px 0 20px;

  }

  .modal-wrapper {
    border-bottom-left-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
  }

  .modal-toolbar ion-title {
    font-size: 20px !important;
    font-family: Yanone;
    color: #414549;
    text-transform: capitalize;
    letter-spacing: 1px;
    text-align: left;
    padding: 5px 0px;
    font-weight: 400 !important;
  }

  .v2-modal-heading-container{
    padding-top: 5px;
  }
  .v2-modal-icon {
    font-size: 32px !important;
    margin-right: 10px;
    color: $base-color;
  }
  .v2-modal-subtitle{
    font-size: 12px;
    opacity: 0.5;
    font-family: poppins;
    letter-spacing: 0.1px;
    line-height: 10px;
  }
  .v2-modal-title{
    font-family: poppins;
    text-transform: capitalize;
    font-weight: 500;
    letter-spacing: 0.1px;
  }

  .v2-modal-content-container{
    padding-left: 20px;
  }

  .v2-modal-container{
    .map {
      position: relative;
      overflow: hidden;
      border-radius: 3px;
      border: 1px solid #e6e6e6;
    }

    .location .select-location-container{
      padding: 0;

      .title-1 {
        font-family: poppins;
        text-transform: capitalize;
        font-weight: 400;
        color: #414549;
        /* font-weight: bold; */
        font-size: 16px;
        position: relative;
        margin-left: 20px;
        padding-left: 20px;
        &::before {
          content: "";
          display: block;
          width: 6px;
          height: 6px;
          position: absolute;
          left: -18px;
          top: 3px;
          border-radius: 100%;
          border: 3px solid #F5CD0B;
        }
      }

    }

  }


}


.pop-up-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: left;
  padding: 10px 10px 30px;
}
.v2-ordering-method{
  .top-container{
    .method-container{
      display: inline-block;
      height: 150px;
      width: 120px;
      position: relative;
      .method-icon{
        width: 52px;
        height: 52px;
        display: block;
        background-image: url("/assets/v2/images/order-method-icons/1.png");
        border-radius: 10px;
        background-color: #ECECEC;
        transition: 0.5s;
        transition-delay: 0.5s;
        &.method-1-icon{
          background-position-y: 104px;
        }
        &.method-2-icon{
          background-position-y: 0;
        }
        &.method-3-icon{
          background-position-y: 52px;
        }
      }
      .method-text{
          font-family: poppins;
          color: var(--v2-gray);
          font-size: 12px;
        transition: 0.5s;
        transition-delay: 0.5s;
      }
      .method-abs-container{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
        &.active{
          .method-icon{
            background-color: var(--v2-base);
          }
          .method-text{
            color: var(--v2-base);
          }
        }
      }

      &.method-1-container, &.method-3-container{
        .method-abs-container{

          top: 50px;

        }
      }

    }
  }
  .bottom-container{
    .pick_up, .delivery, .dine_in {
      width: 22px;
      height: 82px;
      display: inline-block;
      background-image: url("/assets/v2/images/order-method-icons/arrow.png");
      margin: -50px auto auto;
      transform: rotate(-45deg);
      transform-origin: 11px 82px;
      transition: 0.5s;
    }
    .pick_up{transform: rotate(-45deg);}
    .delivery{transform: rotate(0deg);}
    .dine_in{transform: rotate(45deg);}

  }
}

.method-h2{
    font-family: poppins;
    font-size: 15px;
    color: var(--v2-black);
  span{
    color: var(--v2-red);
    font-weight: bold;
  }
}
.method-description{
    font-family: poppins;
    font-size: 14px;
    color: var(--v2-black);
    opacity: .5;

}

.modal-textbox{
  padding: 0;
  input.native-input{
    padding: 0 !important;
  }
}

.small-title {
  font-size: 16px;
  color: var(--v2-black);
  font-family: Yanone;
  opacity: 0.6;
  letter-spacing: 1.5px;
  font-weight: 400;
  min-width: 184px;
  &::before {
    display: inline-block;
    content: "";
    width: 3px;
    height: 25px;
    background: red;
    margin-bottom: -7px;
    margin-right: 5px;
    border-radius: 10px;
  }
}


.v2-modal-content-container{
  padding-left: 20px;
  padding-right: 20px;
}


.modal-list-item{
  margin-bottom: 5px;
  margin-top: 5px;
}

.modal-input{
  width: 100%;
  border: none;
  font-family: poppins;
  font-size: 14px;
}

.item-content{
  width: 100%;
}
