.v2-header{

  width: 100%;
  display: block;
  height: 680px;
  background-image: url("/assets/v2/images/headers/6.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  font-family: Yanone;
  letter-spacing: 1px;


  .v2-headerbackdrop{
    background-color: rgba(0,0,0,0.5);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .v2-header-overlay{
    position: relative;
    z-index: 9999;
  }



  .v2-searchbar-container{
    text-align: center;
    margin-top: 250px;

    h1{
      color: #FFFFFF;
      font-family: Fira;
      font-size: 65px;
      letter-spacing: 10px;
    }

    h3{
      color: $base-color;
      font-size: 28px;
      letter-spacing: 4px;
      margin-top: 0px;
      font-weight: bold;
    }


    .v2-searchbar-parent-container{
      width: 750px;
      margin: auto;
    }

    .v2-searchbar-inner{
      width: 100%;
      background-color: #FFFFFF;
      border-radius: 5px;
      padding: 5px;
    }

    .v2-searchbar{

    }

    .v2-searchinput{
      width: 100%;
      input{
        width: 100%;
        background-color: transparent;
        border: 0;
        padding: 8px;
      }

    }

    .v2-location-icon{
      font-size: 20px;
      margin-right: 5px;
      color: $color-red;
    }


    .v2-search-button{
      background-color: $base-color;
      height: 45px;
      padding: 10px 20px;
      margin-left: 10px;
      border-radius: 5px;
      font-size: 18px;
      color: $color-red;
      font-family: Yanone;
      letter-spacing: 2px;
      font-weight: 500;
    }

  }

  .v2-spec-list{
    color: $base-color;
    font-weight: bold;
    letter-spacing: 3px;
    font-size: 18px;
    margin-top: 40px;
    ul{
      display: inline;
      li{
        display: inline;
        margin-right: 40px;
      }
    }
    .v2-spec-text{
      display: inline-block;
    }
    .v2-spec-icon{
      display: inline-block;
      color: $color-green;
      font-size: 20px;
      transform: translateY(3px);
      margin-left: 5px;
    }
  }


}


.ve-navbar{
  background: #fff;
}

.v2-nav-container{
  width: 100%;
  text-align: center;
  ul{
    margin: 0;
    li{
      display: inline;
    }
  }

  .v2-link{
    padding: 20px 14px;
    display: inline-block;
    transition: 0.3s;
    margin-right: 0;
    &:hover{
      background: #eee;
    }
  }
}
.v2-nav-lang-container{
  .v2-link{
    padding: 20px 14px;
    display: inline-block;
    transition: 0.3s;
    margin-right: 0;
    &:hover{
      background: #eee;
    }
  }
}


.v2-howdoesitwork{
  background-color: #F2F2F2;
  padding-top: 100px;
  min-height: 300px;
  overflow: hidden;
}


.v2-steps{
  margin-bottom: 150px;
  margin-top: 50px;
  position: relative;
}

.v2-step{
  text-align: center;
}
.v2-step-image{
  width: 123px;
  height: 123px;
  background-position: center;
  background-repeat: no-repeat;
  //display: inline-block;
  background-size: cover;
  margin: auto;
}

.v2-step-number{
  font-size: 24px;
  font-weight: bold;
  color: $color-red;
  font-family: Yanone;
  background-color: $base-color;
  display: inline-block;
  padding: 4px 0px;
  height: 33px;
  width: 33px;
  text-align: center;
  border-radius: 100%;
  margin-top: 10px;
}
.v2-step-text{
  color: $color-gray;
  font-family: Yanone;
  font-size: 18px;
  font-weight: normal;
  width: 200px;
  margin: auto;
  margin-top: 10px;
  letter-spacing: 0.5px;
}
  .v2-dots-container {
    height: 154px;
    width: 166px;
    display: block;
    position: absolute;
    right: 0;
    transform: translateX(50%);
    //background-color: #d6d6d6;

    &.updown{
      .v2-dots-inner{
        transform: scale(0.7) scaleY(-1);
      }
    }

    .v2-dots-inner{
      height: 154px;
      width: 166px;
      display: block;
      position: relative;
      overflow: hidden;
      transform: scale(0.7) scaleY(1);

      .v2-dots-layer1{
        height: 154px;
        width: 166px;
        display: block;
        position: absolute;
        background-color: #d6d6d6;
      }

      .v2-dots-layer2{
        height: 154px;
        width: 166px;
        display: block;
        position: absolute;
        background-color: $color-red;
        left: -100%;
        transition: 0.5s;
      }

      .v2-dots-layer3{
        height: 154px;
        width: 166px;
        display: block;
        position: absolute;
        background-color: transparent;
        background-image: url("/assets/v2/images/dots/dots-bg.png");

      }

    }
  }

.v2-getapp-container{
  height: 450px;
  width: 100%;
  display: block;
  background-image: url("/assets/v2/images/app-bg.jpg");
  box-shadow: 0 0 45px 5px #2d2d2d inset;


  .side-left{
    display: inline-block;
    width: calc(100% - 280px);
    vertical-align: top;
    h2{
        text-align: center;
        font-size: 48px;
        margin-top: 120px;
        color: #fff;
      letter-spacing: 2px;

    }
    h4{
      text-align: center;
      font-size: 18px;
      margin-top: 20px;
      color: $base-color;
      letter-spacing: 1px;
    }

    .default-item {
      display: inline-block;
      .icon, .text{
        font-weight: 500;
        color: #FFFFFF;
        font-family: Yanone;
        letter-spacing: 1px;
        font-size: 18px;

      }
      .icon{
        ion-icon{
          margin-bottom: 0;
          font-size: 25px;
          margin-right: 5px;
        }
      }
      *[slot="start"] {
        margin-inline-start: 5px;
        margin-inline-end: 5px;
      }
    }

  }
  .side-right{
    display: inline-block;
    width: 280px;
  }

  .v2-phone{
    display: block;
    background-image: url("/assets/v2/images/hand.png");
    height: 520px;
    width: 263px;
    background-size: cover;
    margin-top: -80px;
  }

  .v2-app-button-container{
    max-width: 800px;
    margin: 50px auto auto;
    width: 100%;
    ion-col{
      text-align: right;
      &:nth-child(2){
        text-align: left;
        padding-left: 20px;
      }
    }
  }

}


.v2-most-popular-container{
  margin-top: 80px;
  .v2-title{
    font-size: 45px;
    letter-spacing: 3px;
    font-weight: bold;
    font-family: Yanone;
  }

  .v2-subtitle {
    font-weight: 500;
    letter-spacing: 2px;
    font-family: Yanone;
    font-size: 30px;
    letter-spacing: 4px;
    margin-top: 15px;
  }


}
.v2-city-slide{
  padding: 20px;
  img{
    width: 100%;
    //filter: grayscale(0.8);
    &:hover{
      filter: grayscale(0);
    }
  }
}
