
.v2-header-left{
  float: left;
}
.v2-header-right{
  float: right;
}
.v2-header-center{
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);

  .logo{
    background: #fff;
    padding: 20px 10px;
    border-radius: 0 0 15px 15px;
    img{
      width: 150px;
    }
  }
}

.v2-header-nav{
  position: relative;
  color: #fff;
  font-family: Yanone;
  font-weight: 500;
  font-size: 18px;
  padding-top: 10px;
}
