@font-face {
  font-family: Yanone;
  src: url("/assets/fonts/yanone/YanoneKaffeesatz-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Yanone;
  src: url("/assets/fonts/yanone/YanoneKaffeesatz-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Yanone;
  src: url("/assets/fonts/yanone/YanoneKaffeesatz-ExtraLight.ttf");
  font-weight: 200;
}

@font-face {
  font-family: Yanone;
  src: url("/assets/fonts/yanone/YanoneKaffeesatz-light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: Yanone;
  src: url("/assets/fonts/yanone/YanoneKaffeesatz-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: Yanone;
  src: url("/assets/fonts/yanone/YanoneKaffeesatz-Medium.ttf");
  font-weight: 500;
}


@font-face {
  font-family: Fira;
  src: url("/assets/fonts/FiraSans-Bold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: Fira;
  src: url("/assets/fonts/FiraSans-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Fira;
  src: url("/assets/fonts/FiraSans-Light.ttf");
  font-weight: 300;
}
