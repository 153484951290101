//$base-color : #F79F1F;
$base-color : #F5CD0B;
//$color-red : #C0392B;
$color-red : #ee5253;
$color-gray : #414549;
$color-black : #414549;

$color-green : #0BC307;







:root {
  --v2-red: #{$color-red};
  --v2-gray: #{$color-black};
  --v2-base: #{$base-color};
  --v2-black: #{$color-black};
}

