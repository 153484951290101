@import "colors";
@import "fonts";
@import "landingpage";
@import "howdoesitanimation";
@import "modal";
@import "testanimation";
@import "menu";
@import "nav";
@import "buttons";

@import "mobile/base";


.v2-container{
  max-width: 1220px;
  width: 100%;
  margin: auto;
  font-family: Yanone;
}

.clear{
  clear: both;
}

.v2-link{
  margin-right: 15px;
  text-decoration: none;
  color: $color-red;
  font-family: Yanone;
  font-size: 18px;
  letter-spacing: 2px;
  cursor: pointer;
  &.white{
    color: #fff;
  }
}

.v2-title {
  font-family: Fira;
  color: #414549;
  margin: 0;
  text-align: center;
  font-size: 48px;
  letter-spacing: 10px;
}


.v2-subtitle {
  font-family: Fira;
  color: #AEAEAE;
  margin: 0;
  text-align: center;
  font-size: 20px;
  letter-spacing: 5px;
  font-weight: 300;
  margin-top: 30px;
}
.v2-red-title {
  font-family: Yanone;
  color: $color-red;
  margin: 0;
  text-align: center;
  font-size: 24px;
  letter-spacing: 2px;
  font-weight: 500;
  margin-top: 10px;
  text-transform: uppercase;
}

.v2-navbar{
  background: #fff;
  box-shadow: 0 0 5px #9b9b9b;
}
